import React from 'react';
import Page from '../components/Page';
import SEO from '../components/seo';
import Cocktails from '../components/Cocktails';
import WipeTransition from '../components/WipeTransition';

const CocktailsPage = props => (
  <Page
    transitionStatus={props.transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <SEO
      title="Liberty Call Distilling Co."
      keywords={[
        'San Diego',
        'Distilleries',
        'Craft',
        'Spirits',
        'Cocktails',
        'Distillery',
        'Whiskey',
        'Gin',
        'Rum',
        'Vodka',
        'Liquor',
        'Local',
        'California',
      ]}
    />
    <Cocktails />
    {props.transitionStatus === 'entering' && (
      <WipeTransition animation="finishRightToLeft" />
    )}
  </Page>
);

export default CocktailsPage;
