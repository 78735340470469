import React, { useState } from 'react';
import styled from 'styled-components';
import RosemaryGinCocktail from './backgrounds/cocktailSection/rosemaryGinCocktail';
import CocktailA from './backgrounds/cocktailSection/cocktailA';
import CocktailB from './backgrounds/cocktailSection/cocktailB';
import Cocktail2A from './backgrounds/cocktailSection/cocktail2A';
import Cocktail2B from './backgrounds/cocktailSection/cocktail2B';
import Cocktail3A from './backgrounds/cocktailSection/cocktail3A';
import Cocktail3B from './backgrounds/cocktailSection/cocktail3B';
import { SplitLayout, Content } from '../components/styles/Layout';

const StyledScrollTrigger = styled.div`
  height: 100vh;
`;

export function ScrollTrigger(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    if (isVisible) props.callBack();
  }, [isVisible]);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisible(true);
            // Uncomment line below to let animation only happen once.
            // observer.unobserve(domRef.current);
          } else setVisible(false);
        });
      },
      // IntersectObserver Options
      { threshold: 0.8 }
    );
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return <StyledScrollTrigger ref={domRef} />;
}

export const VerticalHeader = styled.h2`
  display: ${props => (props.isVisible ? 'visible' : 'hidden')};
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
  overflow: visible;
  writing-mode: vertical-lr;
  font-size: 7.5vh;
  margin: 0;
  text-align: center;
  padding: 10px 0;
  opacity: 0.8;
  position: fixed;
  color: white;
  top: ${props => props.theme.headerHeight};
  transform: translateX(
      ${props =>
        props.shift
          ? props.shift
          : props.isVisible
          ? '0%'
          : `${props.left ? '' : '-'}100%`}
    )
    ${props => props.left && `rotate(180deg)`};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: transform 0.3s, opacity 0s;
  ${props =>
    props.left &&
    `
    right: 50vw;
  `}
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    position: static;
  }
`;

const CocktailPageLayout = styled(SplitLayout)`
  color: ${props => props.theme.navy(0.9)};
  grid-template-areas:
    'zeroA zeroB'
    'oneA oneB'
    'twoA twoB'
    'threeA threeB';
  .zeroA {
    grid-area: zeroA;
  }
  .zeroB {
    grid-area: zeroB;
  }
  .oneA {
    grid-area: oneA;
  }
  .oneB {
    grid-area: oneB;
  }
  .twoA {
    grid-area: twoA;
    text-align: right;
  }
  .twoB {
    grid-area: twoB;
  }
  .threeA {
    grid-area: threeA;
  }
  .threeB {
    grid-area: threeB;
  }
  .contentSide {
    background: white;
    overflow: visible;
  }
  @media (min-width: ${props => props.theme.mobileBreakPoint}) {
    overflow: scroll;
    height: 100vh;
    .contentSide {
      height: 200vh;
      width: 100%;
      z-index: 2;
    }
    .imgSide {
      .img {
        max-width: 750px;
      }
    }
    .topSection {
      height: 100vh;
    }
    .imgSide {
      height: 100vh;
    }
    .innerContents {
      position: sticky;
      top: 0vh;
      height: auto;
    }
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    grid-template-areas:
      'zeroA'
      'zeroB'
      'oneB'
      'oneA'
      'twoA'
      'twoB'
      'threeB'
      'threeA';
    .imgSide {
      height: 50vh;
      .overlap {
        display: none;
      }
    }
  }
`;

const Cocktails = props => {
  return (
    <CocktailPageLayout>
      <Content className="zeroA contentSide topSection">
        <div className="innerContents">
          <hr />
          <h4 style={{ textAlign: 'left', width: '100%' }}>
            ACTUALLY CRAFT COCKTAILS
          </h4>
          <h3>MADE WITH OUR VERY OWN CRAFT SPIRITS</h3>
          <p>
            At our Distillery Restaurant and Tasting room in Barrio Logan, you
            can try a wide variety of handmade cocktails using our very own
            spirits paired with fresh ingredients, and mixers made in house.
            From timeless classics, to our very own unique originals, we have a
            perfect cocktail for everyone.
          </p>
        </div>
      </Content>
      <div className="zeroB topSection">
        <CocktailA
          className="sideB"
          overlayColor="darkgray"
          overlayOpacity="0.1"
          fixed
        />
      </div>
    </CocktailPageLayout>
  );
};

export default Cocktails;
